/* body.active-modal {
  overflow-y: hidden;
} */

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.popup, .overlay {
  width: fit-content;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49,49,49,0.8);
}
.popup-content {
  position: fixed;
  right: 11px;
  bottom: 44px;
  line-height: 1.4;
  background: #f1f1f1;
  border-radius: 3px;
  max-width: 325px;
  border-radius: 9px;
}

.close-popup {
  position: absolute;
  top: -17px;
  right: -14px;
  padding: 5px 7px;
  border: none;
  width: 40px;
  height: 45px;
  cursor: pointer;
}

